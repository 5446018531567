@import '../node_modules/@syncfusion/ej2-material-theme/styles/material.css';
::view-transition-old(root){
	animation: entrada 0.5s ease 0s 1 normal forwards;
}


/* You can add global styles to this file, and also import other style files */
// @import '@angular/cdk/overlay-prebuilt.css';
// @import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=fallback');
// @import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,400,500,600,700&display=fallback');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=auto');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=fallback');


/* @import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '@angular/material/prebuilt-themes/pink-bluegrey.css';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '@angular/material/prebuilt-themes/purple-green.css'; */

@import "app/theme/styles/base";
@import "app/theme/styles/theme";

@import "ngx-smart-modal/ngx-smart-modal";
@import "app/app.component";

@import "@ng-select/ng-select/themes/material.theme.css";

@import "animate.css/animate.min";

.mat-drawer-content{
	// background-image: url('./assets/images/others/fondo-imagenes.png');
	// background-attachment: fixed;
	background-color: #ffffff; // color de fondo de toda la app
}

/* html{
	scroll-behavior: smooth;
} */
*{
	// font-family: 'Poppins', sans-serif; // TODO: revisar cambio a todos los elementos
	font-display: swap;
}
.modal-backdrop {
    // display:none;
	overflow: auto;
}
.b24-widget-button-wrapper.b24-widget-button-position-bottom-right, .b24-widget-button-wrapper.b24-widget-button-position-bottom-middle, .b24-widget-button-wrapper.b24-widget-button-position-bottom-left{
	bottom: 5px;
	right: 5px;
}
.app.iegrupo .mat-toolbar .mat-form-field-underline{
	display: none;
}


// swiper nosotros
.swiper.swiper-nosotros-1 {
	position: relative;
	width: 70%;
	height: 100%;
	padding: 120px 0;
	//margin-top: -200px;
	top: -200px;
	display: flex;
	justify-content: center;
  }
.swiper-button-prev:after, .swiper-button-next:after{
	font-size: 20px !important;
	color: #ffe100;
	font-weight: 900;
}
.swiper-button-next, .swiper-button-prev{
	cursor: pointer;
	// top: auto !important;
	width: 40px !important;
    height: 40px !important;
    /* background: #ffe100; */
	border: none;
    border-radius: 50%;
	position: absolute;
    //box-shadow: 0px 0px 5px #000;
    color: #ffe100;
	font-size: 26px;
	text-align: center;
	// bottom: 5px;
	z-index: 10;
}
/* .swiper-button-prev{
	// left: 35%;
}
.swiper-button-next{
	//right: 35%;
} */

#seccion-carrusel{
	.swiper-button-next, .swiper-button-prev{
		border: solid 2px #ffe100;
	}
	.swiper-button-prev{
		left: 35%;
	}
	.swiper-button-next{
		right: 35%;
	}
}
.swiper-nosotros-1 .swiper-slide-active{			
	transform: scale(1.5) !important;
	z-index: 13 !important;
}

.swiper-slide-active{			
	img{
		width: 100%;
		height: 100%;
	}
	// transform: scale(1.5) !important;
	z-index: 13 !important;
}

.swiper-pagination{
	/* background: blue;
	padding: 110px; */
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 5px !important;
	width: 25%;
}
.swiper-pagination-bullet{
	width: 12px;
	height: 12px;
	display: flex;
	background: gray;
	border-radius: 50%;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active{
	background-color: #FFE100;
	width: 20px;
	height: 20px;
	/* box-shadow: 0 0 1px 2px #cacaca; */
}
// FIN swiper nosotros


input[type=tel]{
	width: 100% !important;
}


.registro-modal {
	// padding-bottom: 10px;
	.mat-dialog-container{
		padding: 0 !important;
		position: relative !important;
		overflow: initial !important;
	}
	#registro-modal-formulario{
		background-color: #ffffff;
		margin: 0 0 10px 0;
	}
	::-webkit-scrollbar {
		display: none;
	}
}
@keyframes example {
	from {
		right: -100%;
	}
	to {
		right: 0;
	}
}
@keyframes salir {
	from {
		right: 5px !important;
	}
	to {
		right: -50px !important;
	}
}

.hoja-lateral {
	position: absolute !important;
	right: 5px !important;
    height: auto !important;
    width: 600px !important;
	bottom: 80px !important;
	.mat-dialog-container{
		padding: 0 !important;
		position: relative !important;
		overflow: initial !important;
		background-color: #ffffff !important;
		animation-name: example !important;
		animation-duration: 2s !important;
		box-shadow: 2px 2px 2px #000000;
	}
	::-webkit-scrollbar {
		display: none;
	}
}
.cerrar-hoja-lateral {
	.mat-dialog-container{
		animation-name: salir !important;
		animation-duration: 2s !important;
		box-shadow: 2px 2px 2px #000000;
	}
	::-webkit-scrollbar {
		display: none;
	}
}

//modal 
.modal-edit-art {
	.mat-dialog-container{
		padding: 0 !important;
		position: relative !important;
		overflow: initial !important;
	}
	form {
		width: 100%;
		background-color: #ffffff;
		margin: 0 0 10px 20px;
	}
	::-webkit-scrollbar {
		display: none;
	}
}

@media only screen and (max-width: 600px){
	.cc-window {
		.cc-message {
			font-size: 8px;
			line-height: normal;
		}
		.cc-link {
			font-size: 12px;
		}
	}
}
.cdk-overlay-backdrop {
	position: fixed; 
	width: 100vw !important;
	height: 100vh !important;
}

.pagos-modal {
	// padding-bottom: 10px;
	.mat-dialog-container{
		padding: 0 !important;
		position: relative !important;
		overflow: initial !important;
		background: none !important;
		box-shadow: none !important;
	}
	#pagos-modal-formulario{
		background-color: #ffffff;
		margin: 0 0 10px 0;
	}
	::-webkit-scrollbar {
		display: none;
	}
}
/* 
.cdk-global-overlay-wrapper{
	pointer-events:auto!important;//beabletoscrollevent
	overflow-y:scroll!important;
	align-items:start!important;//notcenterthedialog
	z-index:auto!important;//removez-index
}
.cdk-overlay-pane{
	margin:1rem;//entercodehere
	z-index:1002; // over the overlay
 } */
 .cdk-global-scrollblock{
	z-index: auto;
 }

 app-home, app-home-bogota, app-home-bucaramanga, app-home-cali, app-home-cartagena, app-home-cucuta, app-home-ibague, app-home-manizales,
 app-home-medellin, app-home-monteria, app-home-mosquera, app-home-neiva, app-home-pereira, app-home-villavicencio{
	display: block;
	/* box-shadow: 1px 1px 5px #666666; */
	border-radius: 0 0 5px 5px;	
 }
.app.iegrupo .mat-toolbar.mat-primary {
    /* background: #ffe100; */
    background: #ffffff;
    color: rgba(0, 0, 0, 0.87);
}

.error-snack {
	background-color: #DC3544 !important;
}
.componente-buscador
.mat-form-field-label{
	height: 50px !important; 
	font-size: 24px !important;
}

/* 
::-webkit-scrollbar{
	width: 20px;
	display: none;
}


::-webkit-scrollbar-thumb{
	background-image: url('./assets/images/others/ie-logo-mant.png');
	background-size: contain;
	background-repeat: repeat-y;
	border-radius: 3em;
}
::-webkit-scrollbar-thumb:hover{
	background-image: url('./assets/images/others/ie-logo-mant.png');
	background-size: contain;
	background-repeat: repeat-y;
}
 */

.custom-tooltip {
	background-color: #ffe100;
	color: black;
	padding: 16px;
	border-radius: 8px;
}


@media screen and (max-width: 400px){
	app-home{
		position: relative;
		/* background-color: red; */
		margin-top: 60px;
	}
}


///////////////////////////////// STYLES PHONE INPUT ///////////////////////////
/* You can add global styles to this file, and also import other style files */
body, .mat-card {
	font-family: 'Poppins', sans-serif;
}

.wrapper {
	margin-bottom: 20px;
}

/*****************************************************
	Custom styling example bellow.
*****************************************************/

.iti {
	display: block !important;
	margin-bottom: 20px;
}

.iti .dropdown-menu.country-dropdown {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-color: #c7cace;
	margin-top: -1px;
}

.iti .iti__country-list {
	box-shadow: none;
	font-size: 14px;
	margin-left: 0;
	width: 244px;
	max-height: 170px;
}

.iti__flag-container.open + input {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.iti .search-container input {
	font-size: 14px;
	border-color: #c7cace;
	border-radius: 0;
	padding: 5px 10px;
}

.iti .search-container input:focus {
	outline: none;
}

@media screen and (max-width: 479px) {
	.iti .iti__country-list {
		width: 88.3vw;
	}
	.account-content{
		padding-top: 100px;
	}
	/* .mat-drawer-content{
		padding-top: 100px;
	} */
}

ngx-intl-tel-input input {
	height: 44px;
	margin-bottom: 20px;
	padding: 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #c7cace;
	border-radius: 4px;
	font-size: 18px;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
	border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
	outline: none !important;
	border-color: #3498db;
	box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
	background-color: #e5eaf1;
}
/// END STYLES PHONE INPUT 
/// 
 
/// unidades landings
.landing-img-desc-unidad-negocio{
	margin-top: 10px;
	padding: 0 30px;
}
/// END unidades landings
.marca-product-pagination{

	.ngx-pagination .current {
		
		background: red !important;
		color: #fefefe !important;
		cursor: default !important;
	}
}
.contenedor-productos-marca {
	.mat-radio-label{
		font-family: 'Poppins', sans-serif;
		overflow: hidden;
		white-space: inherit;
		align-items: normal;
	}
	.mat-radio-outer-circle,
	.mat-radio-inner-circle {
		border-radius: 0 !important;
		width: 18px !important;
		height: 18px !important;
		border: solid 1px #818588 !important;
	}
	.mat-radio-label-content{
		color: #818588;
	}
	.mat-radio-checked{
		color: #1A1A1A;
	}
	
}

// globales para "nosotros"
@media screen and (max-width: 992px){
	app-nosotros{
		background-color: #ffffff;
	}
	
	.swiper-nosotros-1{
		overflow: hidden !important;
        margin-top: -75px !important;
		.swiper-pagination{
			
			bottom: 70px !important;
			
		}
		.swiper-pagination-bullet{
			margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 11px) !important;
		}
	}
	.swiper.swiper-nosotros-1{
		width: 100% !important;
	}
	.swiper.swiper-nosotros-1 .swiper-wrapper{
		min-height: 470px;
	}
	.swiper-nosotros-1 .swiper-slide-active{
		transform: scale(1) !important;
	}
	
	.swiper-button-next, .swiper-button-prev{
		top: 100%;
		width: 30px !important;
		height: 30px !important;
	}
	.seccion-carrusel{
		.swiper-pagination{
			background-color: green !important;
		}
	}
	.ie-timeline
	.mat-horizontal-stepper-header-container{
		margin-top: -40px;
	}

	.swiper-button-prev:after, .swiper-button-next:after{
		font-size: 10px !important;
		
	}
	#contenedor-aliados-home{
		.swiper{
			padding: 0 10px;
		}
		.swiper-button-prev,
		.swiper-button-next{
			width: 40px !important;
			height: 40px !important;
			cursor: pointer;
			top: 50%;
			border: none;
			border-radius: 50%;
			position: absolute;
			color: #ffe100;
			font-size: 26px;
			text-align: center;
			z-index: 10;
			font-weight: 700;
		}
	
		.swiper-button-prev{
			left: 0 !important;
		}
		.swiper-button-next{
			right: 0 !important;
		}
		.swiper-button-prev:after, .swiper-button-next:after{
			font-size: 18px !important;
		}
	}
	
}





@keyframes entrada {
	0% {
		animation-timing-function: ease-in;
		opacity: 0;
		transform: translateY(-250px);
	}

	38% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: translateY(0);
	}

	55% {
		animation-timing-function: ease-in;
		transform: translateY(-65px);
	}

	72% {
		animation-timing-function: ease-out;
		transform: translateY(0);
	}

	81% {
		animation-timing-function: ease-in;
		transform: translateY(-28px);
	}

	90% {
		animation-timing-function: ease-out;
		transform: translateY(0);
	}

	95% {
		animation-timing-function: ease-in;
		transform: translateY(-8px);
	}

	100% {
		animation-timing-function: ease-out;
		transform: translateY(0);
	}
}