.myspinner .black-overlay{
	position: fixed;
}
:root{
	--tamanho-fuente: 14px !important;
	--alto-menu: 70px !important;
	--color-texto-gris-ie: #c4c4c4 !important;
	--animate-delay: 7s;
}
/* .cdk-overlay-container{
	backdrop-filter: blur(5px);
} */
ngx-smart-modal,
ngx-smart-modal .ng-star-inserted.overlay.nsm-overlay-open .nsm-dialog.nsm-dialog-animation-btt.nsm-dialog-open .nsm-content .mat-card-content
{
	max-height: 60vh !important;
	overflow-y: scroll;
	// padding: 20px;
	/* width */
	::-webkit-scrollbar {
		width: 5px;
	}
	/* Track */
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px #cecece; 
		border-radius: 5px;
	}
	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #ffe100; 
		border-radius: 5px;
	}
	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #ffe100; 
	}
}
mat-card-content .ng-star-inserted.overlay.nsm-overlay-open .nsm-dialog.nsm-dialog-animation-btt.nsm-dialog-open {
	width: 100%;
}



mat-card-content p {
	padding: 10px;
	margin: 10px 5;
}
mat-card-content *{
	font-size: 10px !important;
}
input, textarea, [contenteditable] {
	caret-color: #000000 !important;
}
.mat-tab-group[mat-stretch-tabs]>.mat-tab-header .mat-tab-label{
	height: auto !important;
	background-color: #cecece;
}
.swiper-button-next.swipe-arrow.mat-mini-fab.mat-accent,
.swiper-button-prev.swipe-arrow.mat-mini-fab.mat-accent {
	background-color: #ffe100;
}
.texto-link {
	text-decoration: underline;
	cursor: pointer;
}
.cssload-cssload-wrap2 {
	width: 146px;
	height: 146px;
	overflow: hidden;
}
.cssload-wrap {
	position: absolute;
	width: 97px;
	height: 97px;
	left: 50%;
	top: 50%;
	margin-top: -49px;
	margin-left: -49px;
	transform: scale(2);
		-o-transform: scale(2);
		-ms-transform: scale(2);
		-webkit-transform: scale(2);
		-moz-transform: scale(2);
}
.cssload-wrap .cssload-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 100;
	box-shadow: 0 0 24px 37px rgb(255,255,255) inset;
		-o-box-shadow: 0 0 24px 37px rgb(255,255,255) inset;
		-ms-box-shadow: 0 0 24px 37px rgb(255,255,255) inset;
		-webkit-box-shadow: 0 0 24px 37px rgb(255,255,255) inset;
		-moz-box-shadow: 0 0 24px 37px rgb(255,255,255) inset;
}
.cssload-wrap .cssload-cogWheel {
	position: absolute;
	top: 50%;
	margin-top: -24px;
	width: 49px;
	height: 49px;
}
.cssload-wrap .cssload-cogWheel.cssload-one {
	left: -6.25px;
	animation: cssload-rotLeft 1.15s infinite linear;
		-o-animation: cssload-rotLeft 1.15s infinite linear;
		-ms-animation: cssload-rotLeft 1.15s infinite linear;
		-webkit-animation: cssload-rotLeft 1.15s infinite linear;
		-moz-animation: cssload-rotLeft 1.15s infinite linear;
}
.cssload-wrap .cssload-cogWheel.cssload-one .cssload-one:before {
	border: none;
}
.cssload-wrap .cssload-cogWheel.cssload-two {
	right: -6.25px;
	margin-top: -23px;
	transform: rotate(196deg);
		-o-transform: rotate(196deg);
		-ms-transform: rotate(196deg);
		-webkit-transform: rotate(196deg);
		-moz-transform: rotate(196deg);
	animation: cssload-rotRight 1.15s infinite linear;
		-o-animation: cssload-rotRight 1.15s infinite linear;
		-ms-animation: cssload-rotRight 1.15s infinite linear;
		-webkit-animation: cssload-rotRight 1.15s infinite linear;
		-moz-animation: cssload-rotRight 1.15s infinite linear;
}
.cssload-wrap .cssload-cogWheel.cssload-two .cssload-one:before {
	border: none;
}
.cssload-wrap .cssload-cogWheel .cssload-cog {
	position: absolute;
	width: 100%;
	left: 0;
	top: 50%;
}
.cssload-wrap .cssload-cogWheel .cssload-cog:after {
	display: block;
	position: absolute;
	content: ' ';
	border: 2px solid rgb(0,0,0);
	border-left: none;
	height: 9px;
	width: 5px;
	right: -9px;
	top: -4px;
}
.cssload-wrap .cssload-cogWheel .cssload-cog:before {
	display: block;
	position: absolute;
	content: ' ';
	border-left: 2px solid rgb(0,0,0);
	height: 10px;
	width: 4px;
	right: -6px;
	top: -13px;
	transform: rotate(-20deg);
		-o-transform: rotate(-20deg);
		-ms-transform: rotate(-20deg);
		-webkit-transform: rotate(-20deg);
		-moz-transform: rotate(-20deg);
}
.cssload-wrap .cssload-cogWheel .cssload-cog.cssload-one {
	transform: rotate(-80deg);
		-o-transform: rotate(-80deg);
		-ms-transform: rotate(-80deg);
		-webkit-transform: rotate(-80deg);
		-moz-transform: rotate(-80deg);
}
.cssload-wrap .cssload-cogWheel .cssload-cog.cssload-two {
	transform: rotate(-40deg);
		-o-transform: rotate(-40deg);
		-ms-transform: rotate(-40deg);
		-webkit-transform: rotate(-40deg);
		-moz-transform: rotate(-40deg);
}
.cssload-wrap .cssload-cogWheel .cssload-cog.cssload-three {
	transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
}
.cssload-wrap .cssload-cogWheel .cssload-cog.cssload-four {
	transform: rotate(40deg);
		-o-transform: rotate(40deg);
		-ms-transform: rotate(40deg);
		-webkit-transform: rotate(40deg);
		-moz-transform: rotate(40deg);
}
.cssload-wrap .cssload-cogWheel .cssload-cog.cssload-five {
	transform: rotate(80deg);
		-o-transform: rotate(80deg);
		-ms-transform: rotate(80deg);
		-webkit-transform: rotate(80deg);
		-moz-transform: rotate(80deg);
}




@keyframes cssload-rotLeft {
	from {
		transform: rotate(-30deg);
	}
	to {
		transform: rotate(10deg);
	}
}

@-o-keyframes cssload-rotLeft {
	from {
		transform: rotate(-30deg);
	}
	to {
		transform: rotate(10deg);
	}
}

@-ms-keyframes cssload-rotLeft {
	from {
		transform: rotate(-30deg);
	}
	to {
		transform: rotate(10deg);
	}
}

@-webkit-keyframes cssload-rotLeft {
	from {
		transform: rotate(-30deg);
	}
	to {
		transform: rotate(10deg);
	}
}

@-moz-keyframes cssload-rotLeft {
	from {
		transform: rotate(-30deg);
	}
	to {
		transform: rotate(10deg);
	}
}

@keyframes cssload-rotRight {
	from {
		transform: rotate(-174deg);
	}
	to {
		transform: rotate(-214deg);
	}
}

@-o-keyframes cssload-rotRight {
	from {
		transform: rotate(-174deg);
	}
	to {
		transform: rotate(-214deg);
	}
}

@-ms-keyframes cssload-rotRight {
	from {
		transform: rotate(-174deg);
	}
	to {
		transform: rotate(-214deg);
	}
}

@-webkit-keyframes cssload-rotRight {
	from {
		transform: rotate(-174deg);
	}
	to {
		transform: rotate(-214deg);
	}
}

@-moz-keyframes cssload-rotRight {
	from {
		transform: rotate(-174deg);
	}
	to {
		transform: rotate(-214deg);
	}
}


//// TIMELINE
.ie-timeline{
	*{
		transition: all 5ms ease-in-out;
	}
	.mat-step-header{
		border-radius: 50% !important;
		padding: 0 !important; 
		height: 15px;
		width: 15px;
		overflow: hidden;
		background: #a4a4a4;
	}
	.mat-step-header:has(>.mat-step-label-selected){
		width: 30px;
		height: 30px;
		.mat-step-text-label{
			background-color: #ffe100; // color del punto actual
		}
	}
		
	.mat-step-text-label{
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background-color: #ffe100;
	}
	.mat-step-icon{
		display: none !important;
	}
	.mat-step-header.mat-primary.ng-tns-c69-14.mat-horizontal-stepper-header.ng-star-inserted{
		overflow: hidden !important;
		border-radius: 50% !important;
		cursor: pointer;
		
	}
	.mat-step-label.mat-step-label-active.mat-step-label-selected{
		background-color: #ffe100 !important;
	}
	.mat-horizontal-stepper-header {
		&[ng-reflect-selected="true"].mat-step-header {
			background-color: #0000ff;
		}
		&[ng-reflect-selected="false"].mat-step-header {
			background-color: #ff0000;
		}
	}


	.mat-stepper-horizontal-line{
		border-top-color: #ffe100 !important;
		border-top-width: 5px !important;
	}
	// ~
	.mat-horizontal-stepper-header[aria-selected='true'] ~ .mat-stepper-horizontal-line{
		border-top-width: 5px;
		  border-top-color: #dadada !important;  // hsl(0, 0%, 50%) !important;
	}
}
////  FIN TIMELINE



#ele-select-pickers-panel{
	min-height: 50vh;
}

.fc-daygrid-day-events{
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	cursor: pointer;
}

.fc-event,
.fc-event-main{
	cursor: pointer;
	color: #000000 !important;
	font-weight: 500;
	height: auto;
    background: #ffe200;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: balance;
    display: flex;
    flex-direction: column;
}
.fc-col-header-cell{
	text-transform: capitalize;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator { // https://www.scaler.com/topics/date-picker-in-html/
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
.fc-toolbar-title{
	text-transform: capitalize;
}
#contenedor-horarios-toogle{
	.mat-button-toggle-checked{
		background-color: #ffe100 !important;
	}
	.mat-button-toggle-disabled{
		background-color: #a4a4a4;
	}
}
#card-cotizacion-der{
	.mat-form-field-outline,
	.mat-form-field-outline.mat-form-field-outline-thick
	{
		background: #ffffff !important;
		border-radius: 5px !important;
	}
	.mat-focused{
		.mat-form-field-label{
			color: #1a1a1a;
		}
	}
}